export const ICONS = {
  ADD: 'M12 6v6m0 0v6m0-6h6m-6 0H6',
  COLLECTION: 'M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10',
  IMAGE: 'M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z',
  SPARKLES: 'M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z',
  EXPORT: 'M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12',
  GLOBE: 'M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9',
  MOON: 'M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z',
  MOON_SMALL: 'M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z',
  SUN: 'M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z',
  SUN_SMALL: 'M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z',
  VECTOR: 'M7.25 4.25H16.75M19.75 7.25V16.75M16.7502 19.75H5.74023C4.91023 19.74 4.24023 19.07 4.24023 18.25V7.25M4.25 13.25H10.75M10.75 13.25V19.75M10.75 13.25L14.5 9.5M14.5 9.5V13.25M14.5 9.5H10.75M3.5 2.75H6.5C6.91421 2.75 7.25 3.08579 7.25 3.5V6.5C7.25 6.91421 6.91421 7.25 6.5 7.25H3.5C3.08579 7.25 2.75 6.91421 2.75 6.5V3.5C2.75 3.08579 3.08579 2.75 3.5 2.75ZM17.5 16.75H20.5C20.9142 16.75 21.25 17.0858 21.25 17.5V20.5C21.25 20.9142 20.9142 21.25 20.5 21.25H17.5C17.0858 21.25 16.75 20.9142 16.75 20.5V17.5C16.75 17.0858 17.0858 16.75 17.5 16.75ZM17.5 2.75H20.5C20.9142 2.75 21.25 3.08579 21.25 3.5V6.5C21.25 6.91421 20.9142 7.25 20.5 7.25H17.5C17.0858 7.25 16.75 6.91421 16.75 6.5V3.5C16.75 3.08579 17.0858 2.75 17.5 2.75Z'
};
